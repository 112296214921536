<template>
  <div id="body">
    <div class="headerinfos" style="margin-left: 25px;">
      <p v-if="this.sstatus ==0" @click="$router.go(-2)">健康列表</p>
      <p v-if="this.sstatus ==1" @click="$router.go(-2)">学生列表</p>
      <p>/</p>
      <p @click="$router.go(-1)">视力详情</p>
      <p>/</p>
      <p>更新信息</p>
    </div>
    <div style="width: 80%;margin: auto;">
      <el-form :model="ruleForm" ref="ruleForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="裸眼视力：">
          <el-col :span="12">
            <el-form-item label="左眼：">
              {{stu_list.Leye}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="右眼：">
              {{stu_list.Reye}}
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="球       镜(S)：">
          <el-col :span="12">
            <el-form-item label="左眼：">
              {{stu_list.zqj}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="右眼：">
              {{stu_list.yqj}}
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="柱      镜(C)：">
          <el-col :span="12">
            <el-form-item label="左眼：">
              {{stu_list.zzj}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="右眼：">
              {{stu_list.yzj}}
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="散光轴位(A)：">
          <el-col :span="12">
            <el-form-item label="左眼：">
              {{stu_list.zzw}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="右眼：">
              {{stu_list.yzw}}
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="水平子午线曲率MM1：">
          <el-col :span="12">
            <el-form-item label="左眼：" prop="name">
              {{stu_list.zmm1}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="右眼：" prop="name">
              {{stu_list.ymm1}}
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="水平子午线曲率MM2：">
          <el-col :span="12">
            <el-form-item label="左眼：" prop="name">
              {{stu_list.zmm2}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="右眼：" prop="name">
              {{stu_list.ymm2}}
            </el-form-item>
          </el-col>
        </el-form-item>
        </el-form-item>
        <el-form-item label="水平子午线角度A1：">
          <el-col :span="12">
            <el-form-item label="左眼：" prop="name">
              {{stu_list.za1}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="右眼：" prop="name">
              {{stu_list.ya1}}
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="瞳     距PD：">
          {{stu_list.tongj}}
        </el-form-item>
        <el-form-item label="镜 眼 距VD：">
          {{stu_list.jyj}}
        </el-form-item>
        <el-form-item label="正相对调节：" prop="zxdtj">
          <el-input v-model="ruleForm.zxdtj"
            oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
          </el-input>
        </el-form-item>
        <!--        onkeyup="this.value=this.value.replace(/[^\-?\d.]/g,'')" -->
        <el-form-item label="负相对调节：" prop="fxdtj">
          <el-input v-model="ruleForm.fxdtj"
            oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
          </el-input>
        </el-form-item>
        <el-form-item label="调节灵敏度：">
          <el-col :span="12">
            <el-form-item label="左眼：" prop="zlmd">
              <el-input v-model="ruleForm.zlmd"
                oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="右眼：" prop="ylmd">
              <el-input v-model="ruleForm.ylmd"
                oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
              </el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="眼       压：">
          <el-col :span="12">
            <el-form-item label="左眼：" prop="z_yany">
              <el-input v-model="ruleForm.z_yany"
                oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="右眼：" prop="y_yany">
              <el-input v-model="ruleForm.y_yany"
                oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
              </el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="雾视验光：">
          <el-col :span="12">
            <el-form-item label="雾视：" prop="zwsyg">
              <el-input v-model="ruleForm.zwsyg"
                oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="不雾视：" prop="ywsyg">
              <el-input v-model="ruleForm.ywsyg"
                oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
              </el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="   BCC   ：" prop="bcc">
          <el-input v-model="ruleForm.bcc"
            oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
          </el-input>
        </el-form-item>
        <el-form-item label="APM调节幅度：" prop="apmtj">
          <el-input v-model="ruleForm.apmtj"
            oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
          </el-input>
        </el-form-item>

        <el-form-item label="集合近点：" prop="jhjd">
          <el-input v-model="ruleForm.jhjd"
            oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
          </el-input>
        </el-form-item>
        <el-form-item label="远用眼位：" prop="yyyw">
          <el-input v-model="ruleForm.yyyw"
            oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
          </el-input>
        </el-form-item>
        <el-form-item label="近用眼位：" prop="jyyw">
          <el-input v-model="ruleForm.jyyw"
            oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
          </el-input>
        </el-form-item>
        <el-form-item label="worth4融像：" prop="worth_image">
          <el-select v-model="ruleForm.worth_image" clearable  placeholder="请选择">
            <el-option v-for="item in optionsworth" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="AC/A：" prop="ac_a">
          <el-input v-model="ruleForm.ac_a"
            oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
          </el-input>
        </el-form-item>
        <el-form-item label="远融像：" prop="far_image">
          <el-input v-model="ruleForm.far_image"
            oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
          </el-input>
        </el-form-item>
        <el-form-item label="近融像：" prop="near_image">
          <el-input v-model="ruleForm.near_image"
            oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
          </el-input>
        </el-form-item>


        <el-form-item label="立体视觉：" prop="ltsj">
          <el-radio-group v-model="ruleForm.ltsj">
            <el-radio label="1">无</el-radio>
            <el-radio label="0">有</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="色       盲：" prop="colorblind">
          <el-select v-model="ruleForm.colorblind" clearable  placeholder="请选择">
            <el-option v-for="item in optionsbind" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="色       弱：" prop="colorweak">
          <el-radio-group v-model="ruleForm.colorweak">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="夜       盲：" prop="nightblind">
          <el-radio-group v-model="ruleForm.nightblind">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="视       野：" prop="eyerange">
          <el-radio-group v-model="ruleForm.eyerange">
            <el-radio :label="1">不正常</el-radio>
            <el-radio :label="0">正常</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
        <br>
        <br>
        <br>
      </el-form>
    </div>
  </div>
</template>
<script>
  import {
    healthStuEyeSearch,
    stuEyeEdit,
  } from '@/api/agentApi.js'

  export default {
    data() {
      return {
        props: { multiple: true },
        ruleForm: {
          stuID: '',
          zxdtj: '', //正相对调节
          fxdtj: '', //负相对调节
          zlmd: '', //调节灵敏度-左
          ylmd: '', //调节灵敏度-右
          z_yany: '', //眼压-左
          y_yany: '', //眼压-右
          zwsyg: '', //雾视验光-雾视
          ywsyg: '', //雾视验光-不雾视
          bcc: '', //BCC
          apmtj: '', //APM调节幅度
          ltsj: '', //立体视觉
          colorblind: '', //色盲
          colorweak: '', //色弱
          nightblind: '', //夜盲
          jhjd: '', //集合近点
          yyyw: '', //远用眼位
          jyyw: '', //近用眼位
          worth_image: '', //worth4
          ac_a: '', //ac/a
          far_image: '', //远融像
          near_image: '', //近融像
        },
        student_id: '',
        stu_list: [],
        sstatus: 0,
        optionsworth: [{
          value: '1',
          label: '一点'
        }, {
          value: '2',
          label: '二点'
        }, {
          value: '3',
          label: '三点'
        }, {
          value: '4',
          label: '四点'
        }],
        optionsbind: [{
          value: '0',
          label: '无'
        },{
          value: '1',
          label: '第一类色盲(红)'
        }, {
          value: '2',
          label: '第二类色盲(绿)'
        }, {
          value: '3',
          label: '第三列色盲(紫)'
        }, {
          value: '4',
          label: '全色盲'
        }],
      };
    },
    created() {
      if (localStorage.getItem('sstatus') == 1) {
         this.sstatus =  1
      }
    },
    mounted() {
      this.student_id = this.$route.params.student_id; // 学生id赋值😜
      this.ruleForm.stuID = this.$route.params.student_id;
      this.health_detail();
    },
    methods: {
      submitForm(formName) {

        const data = this.ruleForm;

        if (data.apmtj == "" && data.bcc == "" && data.colorweak == "" && data.eyerange == "" && data.fxdtj == "" &&
          data.ltsj == "" && data.nightblind == "" && data.y_yany == "" && data.ylmd == "" && data.ywsyg == "" && data
          .z_yany == "" && data.zlmd == "" && data.zwsyg == "" && data.zxdtj == "" && data.colorblind == "") {
          this.$message.error('至少填写一项')
          return false
        }
        stuEyeEdit(data).then(res => {
          this.loading = false
          if (res.status.code == 1) {
            this.$message.success('保存成功')
            this.$refs[formName].resetFields();

            this.$router.push({ //页面跳转
              name: 'health_detail', //往这个页面跳转
              params: { //跳转传递的参数
                student_id: this.student_id,
              }
            })

          }
        }).catch(err => {
          console.log(err)
        })
      },
      //重置
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      //视力详情
      health_detail() {
        this.loading = true
        const data = {
          studentid: this.student_id, // 学校ID
        }
        healthStuEyeSearch(data).then(res => {
          this.loading = false
          this.stu_list = res.data.list

        }).catch(err => {
          console.log(err)
        })
      },


    }
  }
</script>
<style lang="scss" scoped>
  .headerinfos {
    display: flex;

    p {
      color: burlywood;

    }

    p:nth-child(5) {
      cursor: pointer;
      color: black;
    }
  }
</style>
